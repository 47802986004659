import { Skeleton } from 'antd';
import defaultimage from '../assets/Transparent-File.png'
import { SET_CART } from '../redux/cartProducts/cartProductsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {selectCart } from '../redux/cartProducts/cartProductsSlice';
import { useEffect, useState } from 'react';


const ProductCard = ({product , addtocart , stock}) => {
const {productname,imageUrl,price,discountPrice,description} = product
const cart = useSelector(selectCart)
const [cartNames, setCartNames] = useState([])
const dispatch = useDispatch()

useEffect(() => {

const names = cart.map(cart => cart.productname)
setCartNames(names)
}, [cart])





  return (
  
  
  <div className={`flex flex-col gap-3 border-2 p-3 card ${!stock ? 'bg-[#ffcccc]' : "bg-white"} sm:min-w-[200px] min-h-[300px]  max-w-full`}>

    {product ? 


(
<>
    <img  src={imageUrl ? imageUrl : defaultimage} alt={productname}  className="w-[35px] h-[35px] " />
    <p className="font-semibold">{productname}</p>
    <div className='flex items-center gap-1'>

    <h1 className={` text-[20px] ${discountPrice ? "line-through text-slate-950 bg-lime-300" : ""}`}>
    <span>€</span>
        {price}
    </h1>
    {discountPrice ? 
    <h1 className='text-[20px] '>
        <span>€</span>
       {price - discountPrice}</h1>
       : ""}
    </div>
    <div className='min-h-[140px]'>
    <p className='break-words'>{description}</p>
    </div>
    
   {(addtocart && stock) && 
    <button 
    disabled={cartNames.includes(productname)}
    onClick={() =>  dispatch(
      SET_CART({
        product
      })
    )} className={`${cartNames.includes(productname) ? 'bg-green-700' : 'bg-blue-700 hover:bg-blue-600'} bg-blue-700 text-white text-[12px] font-semibold py-[5px] px-[6px] rounded-3xl my-4 self-end  transition-all block w-fit`} type='button'>{cartNames.includes(productname) ? 'Added Successfully' : 'Add to Cart' }</button>
    }
   {!stock && 
    <p 
   className={`bg-red-600 text-white text-[12px] font-semibold py-[5px] px-[6px] rounded-3xl my-4 self-end  transition-all block w-fit`} >Out of stock</p>
    }

  </>
    ):(
    <Skeleton paragraph={{ rows: 4 }} active />
    )
  }

  </div>
  )
};

export default ProductCard;
