import React from 'react'
import Hero from '../components/Hero'
import Programs from '../components/Programs'
import Solutions from '../components/Solutions'

const HomePage = () => {
  return (
    <div className='px-[20px]   sm:px-[35px]'>
        <Hero/>
        <Programs/>
        <Solutions/>
    </div>
  )
}

export default HomePage