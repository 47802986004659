import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/Transparent-File.png"
import sertic from '../assets/Sertic_logo.a9aa25df7ff8ba64b9ca.png'
const Footer = () => {




  const footerRef = useRef(null);

  // useEffect(() => {
  //   const options = {
  //     root: null, // viewport
  //     rootMargin: '0px',
  //     threshold: 0.5 // 50% of the footer is visible
  //   };



   
    













  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         const element = document.querySelector('.container');

  //         // Create a new mouse event for mouseenter
  //         const event = new MouseEvent('mouseenter', {
  //           bubbles: true,
  //           cancelable: true,
  //           view: window
  //         });
  //         // Footer is now visible, perform your actions here
  //   // Dispatch the event to the element
  //   element.dispatchEvent(event);
  //         console.log(element);
  //         // You can call functions or set state here
  //       }
  //     });
  //   }, options);

  //   if (footerRef.current) {
  //     observer.observe(footerRef.current);
  //   }

  //   // Cleanup function
  //   return () => {
  //     if (footerRef.current) {
  //       observer.unobserve(footerRef.current);
  //     }
  //   };
  // }, []); // Run effect only once after initial render









  return (
    <footer ref={footerRef} className="bg-[#D9D9D9] mt-[288px] pb-[29px] ">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 text-center sm:text-start max-h-full lg:grid-cols-4 place-items-center ">
        <Link to="/" className="  max-h-full flex items-center justify-center">
        <img src={logo} alt="Adobe Supplier" className=" w-[300px] max-w-full max-h-[290px] h-auto"/>
        </Link>
        <div className="flex flex-col   gap-1  ">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "pending hover:text-blue-800"
                : isActive
                ? "text-blue-800"
                : "hover:text-blue-800"
            }
            to="/"
          >
            Home
          </NavLink>
          <NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/pricing'>Adobe Master Collection 2024</NavLink>
<NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/Apps'>Apps</NavLink>
          <NavLink
            className={({ isActive, isPending }) =>
              isPending
                ? "pending hover:text-blue-800"
                : isActive
                ? "text-blue-800"
                : "hover:text-blue-800"
            }
            to="/about-us"
          >
            About Us
          </NavLink>
        </div>
    
        <a href="https://sertic.nl" target="_blank" className="container" id="container" rel="noreferrer">
    <img src={sertic}  alt="Logo" className="logo w-10 h-10" id="logo"/>
    <p className="text">powered by Sertic</p>
</a>

      </div>
    </footer>
  );
};

export default Footer;
