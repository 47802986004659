import { IoMdClose } from "react-icons/io";
import {
  HIDE_CART,
  SET_CART,
  selectCart,
} from "../redux/cartProducts/cartProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectApps } from "../redux/apps/AppsSlice";
import { Link } from "react-router-dom";

const Cart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(selectCart);
  const apps = useSelector(selectApps);
const [cartTotalPrice, setCartTotalPrice] = useState(0)
  useEffect(() => {
    // const getLocalStorageCart = JSON.parse(localStorage.getItem('cart'))
    const appsCopy = apps;
    const updatedApps = appsCopy.filter((app) =>
      cart.some((cartItem) => app.productname === cartItem.productname)
    );

    if (updatedApps.length) {

      handleTotalCartPrice(updatedApps)
  

      dispatch(
        SET_CART({
          updatedApps,
        })
      );
    }



    
  }, [apps]);


const handleTotalCartPrice = (updatedApps) => {
  const TotalCartPrice = updatedApps?.reduce(  (accumulator, currentValue) => {

    return accumulator + currentValue.total
    
          } , 0)
    
          setCartTotalPrice(TotalCartPrice)
}




const handleCartAdjust = (product) => {

const cartCopy = cart ;
const updatedCart = cartCopy.filter(item => item.productname !== product)
handleTotalCartPrice(updatedCart)
dispatch(
  SET_CART({
    updatedApps : updatedCart 
  })
);

}




  return (
    <div className="fixed w-full  z-30 top-0 bg-[#1a1a1aa1] h-screen">
      <div className="bg-white w-3/4 sm:w-1/2 lg:w-1/3  float-right h-full p-5 gap-5 flex flex-col">
        <div onClick={() => dispatch(HIDE_CART())}>
          <IoMdClose size={20} className="cursor-pointer float-right" />
        </div>
        <h1 className="text-[40px]">Cart:</h1>
        <div className="overflow-y-auto border-y-2 cart">
          {cart.length ? (
            cart.map((item) => {
              if(item.stock === false){
                handleCartAdjust(item.productname)
              }
              return (
                <div key={item.productname} className="p-3 my-2 border-b-2 last:border-b-0 flex flex-col gap-2">
                  <div onClick={() => handleCartAdjust(item.productname)}>
          <IoMdClose size={15} className="cursor-pointer float-right" />
        </div>
                  <div className="flex flex-col gap-3 md:flex-row items-center justify-between text-slate-700">
                    <img
                      src={item.imageUrl}
                      className="w-[30px]"
                      alt={item.productname}
                    />
                    <p>{item.productname}</p>
                  </div>
                  <p className="mt-2 font-medium text-center sm:text-left">
                    <span className="text-slate-700 font-normal">Price:</span>{" "}
                    {item.total}€
                    {item.discountPrice ?
                    <sub className="text-[8px] ms-[2px] py-[2px] px-[4px] rounded-lg bg-slate-300 ">
                       discounted 
                    </sub>
                    : ""}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="text-center">
            <p className=" text-[22px] my-5 ">No products to show. Try adding some</p>
<Link onClick={() => dispatch(HIDE_CART())} className="text-blue-700" to='/Apps'>Check our Apps</Link>
            </div>
          )}
        </div>
        {cart.length ?
<div className="flex mt-5 items-center justify-between">
  <p className="p-3  text-[20px] ">Total Price:</p>
<p className="font-medium">{cartTotalPrice} €</p>
</div>
:
""
}
{!!cart.length &&

        <Link 
        className='bg-blue-700 text-white py-[10px] px-5 rounded-lg my-4  hover:bg-blue-600 transition-all w-full flex justify-center'
        // onClick={handlePayment}
        to="/checkout"
        onClick={() => dispatch(HIDE_CART())}
        >
Continue to checkout
        </Link>
}
      </div>
    </div>
  );
};

export default Cart;
