import React, { useState } from "react";
import adobesupp from '../assets/contact.gif'
import emailjs from '@emailjs/browser';

const ContactUs = () => {



const [formData, setFormData] = useState({name:"" , email:"" , message:""})
const [sending, setSending] = useState(false)
const [message, setMessage] = useState('')




const sendEmail = (e) => {
  e.preventDefault();
  setSending(true);

  // Set up your EmailJS parameters
  const templateParams = {
    from_name: formData.name,
    from_email: formData.email,
    message: formData.message,
  };

 

emailjs.send("service_o26a388","template_hmvlilh", templateParams ,"Vy8r5tRTnAEH1MsCV") 
  

  .then((response) => {
    setMessage('Email sent successfully');
  setSending(false);
setFormData({name:"" , email:"" , message:""})
setTimeout(() => {
  setMessage('')
}, 4000);
        })
        .catch((error) => {
          setMessage('Error sending email , Try Again Later');
  setSending(false);
  setFormData({name:"" , email:"" , message:""})
  setTimeout(() => {
    setMessage('')
  }, 4000);
        });




}











const handleInoutChange = (e) => {
const {id , value} = e.target


const updateForm = {...formData , [id]: value}
setFormData(updateForm)


}



  return (
    <div className="mt-[110px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
<div className="flex items-start justify-center order-2 md:order-1">
<img  src={adobesupp}  alt="" className="max-w-full w-[75%]" />
</div>
<div className="flex flex-col gap-2 items-center order-1 md:order-2 ">
<div className="shadow-lg py-4 px-6 w-3/4">
  <h1 className="mb-[30px] text-[48px] font-semibold font-poppins">Contact Us</h1>
  <p className="text-[20px]">Explore the future with us.<br/>
Feel free to get in touch.</p>
<p className="text-blue-600 text-sm">{message ? message : ''}</p>
<form onSubmit={sendEmail}>
  <div className="flex flex-col gap-2 mt-[34px]">
  <label htmlFor="name">
    Name
    </label> 
<input onChange={(e) => handleInoutChange(e)} type="text" className="p-2 focus:border-2 bg-slate-50 focus:border-slate-200  border-2 border-transparent focus:outline-none rounded-lg" id="name" required placeholder="Full Name" value={formData.name} name="name"  />
  </div>
  <div className="flex flex-col gap-2 mt-[34px]">
  <label htmlFor="email">
    Email
    </label> 
<input onChange={(e) => handleInoutChange(e)} type="email" className="p-2 focus:border-2 bg-slate-50 focus:border-slate-200  border-2 border-transparent focus:outline-none rounded-lg" id="email" required placeholder="Email address" value={formData.email} name="email"  />
  </div>
  <div className="flex flex-col gap-2 mt-[34px]">
  <label htmlFor="message">
    Message
    </label> 
<textarea onChange={(e) => handleInoutChange(e)} className="p-2 focus:border-2 border-2 bg-slate-50 focus:border-slate-200 border-transparent focus:outline-none rounded-lg" id="message" required placeholder="How can we go better?" value={formData.message} name="message"  />
  </div>
  <input type="submit"  value={sending ? "Sending" : "Send Message"} disabled={sending} className={`  ${sending ? 'opacity-35' : 'opacity-100'} bg-blue-700 cursor-pointer text-white py-[10px] px-5 rounded-lg my-4 hover:bg-blue-600 transition-all block w-full`} />
</form>
</div>
</div>
      </div>
    </div>
  );
};

export default ContactUs;
