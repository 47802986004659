import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import {IoIosMenu} from 'react-icons/io'
import { PiShoppingCartDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_CART, selectCart } from '../redux/cartProducts/cartProductsSlice';

const Navbar = () => {
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
    const [mobileNav, setMobileNav] = useState(false)




const handleMobileNav = () => {


  setMobileNav(prev => !prev)
  const body = document.body;
  
    body.classList.toggle('no-scroll');
  

}





  return (
    <div className='' >
<div className=' px-[20px]  py-[10px] sm:px-[80px] flex items-center justify-between max-w-full w-full'>
    <div className='flex items-center  flex-1'>

    <Link to='/' className=' text-2xl sm:text-3xl font-bold break-words w-[118px] ' >
    <svg
    className=' w-[150px] h-auto'
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 2000 2000"
      style={{ enableBackground: 'new 0 0 2000 2000' }}
      xmlSpace="preserve"
    //   width="300" // Set your desired width here
      height="150" 
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;}
          .st1{fill:#ED1C24;}`}
      </style>
      <rect className="st0" width="2000" height="2000" />
      <g>
	<g>
		<path className="st1" d="M399.01,1299.09h14.3l26.67,72.55h-14.51l-6.03-18.09h-26.64l-6.06,18.09h-14.51L399.01,1299.09z     M416.14,1343.64l-9.98-29.94l-10.03,29.94H416.14z"/>
		<path className="st1" d="M478.6,1371.64v-72.55h26.36c7.9,0,14.51,1.62,19.82,4.85c5.31,3.24,9.3,7.6,11.95,13.08    c2.66,5.48,3.99,11.56,3.99,18.24c0,7.36-1.47,13.76-4.39,19.21c-2.93,5.45-7.07,9.67-12.41,12.67c-5.35,3-11.67,4.49-18.95,4.49    H478.6z M526.52,1335.26c0-4.7-0.85-8.84-2.55-12.41c-1.7-3.57-4.16-6.37-7.36-8.38c-3.2-2.01-7.08-3.01-11.65-3.01H492.7v47.82    h12.26c4.7,0,8.63-1.06,11.8-3.17c3.17-2.11,5.58-4.97,7.26-8.58C525.69,1343.92,526.52,1339.83,526.52,1335.26z"/>
		<path className="st1" d="M613.37,1372.15c-5.31,0-10.12-1.02-14.41-3.07c-4.29-2.04-8-4.82-11.14-8.32    c-3.14-3.51-5.55-7.46-7.25-11.85c-1.7-4.39-2.55-8.91-2.55-13.54c0-4.84,0.9-9.45,2.71-13.84c1.8-4.39,4.29-8.31,7.46-11.75    c3.17-3.44,6.93-6.16,11.29-8.18c4.36-2.01,9.09-3.01,14.2-3.01c5.24,0,10.03,1.06,14.36,3.17c4.33,2.11,8.02,4.94,11.09,8.48    s5.43,7.49,7.1,11.85c1.67,4.36,2.5,8.86,2.5,13.49c0,4.77-0.87,9.35-2.61,13.74c-1.74,4.39-4.19,8.31-7.36,11.75    c-3.17,3.44-6.91,6.15-11.24,8.12C623.2,1371.16,618.48,1372.15,613.37,1372.15z M592.33,1335.37c0,3.13,0.47,6.17,1.43,9.09    c0.95,2.93,2.33,5.53,4.14,7.82c1.8,2.28,4.02,4.09,6.64,5.41c2.62,1.33,5.6,1.99,8.94,1.99c3.47,0,6.5-0.7,9.09-2.09    c2.59-1.39,4.78-3.25,6.59-5.57c1.8-2.32,3.15-4.94,4.04-7.87c0.88-2.93,1.33-5.86,1.33-8.79c0-3.13-0.48-6.15-1.43-9.04    c-0.95-2.89-2.35-5.48-4.19-7.77c-1.84-2.28-4.05-4.09-6.64-5.41c-2.59-1.33-5.55-1.99-8.89-1.99c-3.34,0-6.32,0.7-8.94,2.09    c-2.62,1.4-4.84,3.24-6.64,5.52c-1.81,2.28-3.17,4.87-4.09,7.76C592.78,1329.42,592.33,1332.37,592.33,1335.37z"/>
		<path className="st1" d="M748.75,1353.04c0,4.02-1.06,7.41-3.17,10.16c-2.11,2.76-4.96,4.86-8.53,6.29c-3.58,1.43-7.48,2.14-11.7,2.14    h-35.05v-72.55h38.83c3.4,0,6.35,0.91,8.84,2.71c2.49,1.81,4.39,4.12,5.72,6.95c1.33,2.83,1.99,5.78,1.99,8.84    c0,3.48-0.89,6.76-2.66,9.86c-1.77,3.1-4.36,5.43-7.77,7c4.15,1.23,7.44,3.44,9.86,6.64    C747.54,1344.29,748.75,1348.27,748.75,1353.04z M704.41,1311.15v18.29h18.8c1.57,0,3-0.38,4.29-1.13    c1.29-0.75,2.33-1.8,3.12-3.17c0.78-1.36,1.18-2.96,1.18-4.8c0-1.91-0.36-3.53-1.07-4.85c-0.72-1.33-1.67-2.38-2.86-3.17    c-1.19-0.78-2.54-1.17-4.04-1.17H704.41z M734.55,1350.28c0-1.84-0.38-3.49-1.13-4.96c-0.75-1.46-1.75-2.64-3.01-3.53    c-1.26-0.88-2.71-1.33-4.34-1.33h-21.66v19.21h20.95c1.7,0,3.25-0.42,4.65-1.27c1.4-0.85,2.5-1.99,3.32-3.43    C734.14,1353.55,734.55,1351.99,734.55,1350.28z"/>
		<path className="st1" d="M840.81,1359.27v12.36h-50.37v-72.55h49.45v12.36h-35.35v17.47h30.45v11.44h-30.45v18.9H840.81z"/>
		<path className="st1" d="M980.89,1318.1c-0.48-0.47-1.35-1.11-2.61-1.89c-1.26-0.78-2.79-1.58-4.6-2.4c-1.81-0.82-3.77-1.5-5.88-2.04    c-2.11-0.54-4.26-0.82-6.44-0.82c-3.82,0-6.69,0.7-8.63,2.08c-1.94,1.39-2.91,3.41-2.91,6.05c0,1.97,0.61,3.53,1.84,4.68    c1.23,1.16,3.07,2.14,5.52,2.95c2.45,0.81,5.52,1.69,9.2,2.64c4.77,1.15,8.9,2.56,12.41,4.22c3.51,1.66,6.2,3.83,8.07,6.51    c1.87,2.68,2.81,6.25,2.81,10.72c0,3.8-0.72,7.05-2.15,9.76c-1.43,2.71-3.39,4.93-5.88,6.66c-2.49,1.73-5.35,2.98-8.58,3.76    c-3.24,0.78-6.66,1.17-10.27,1.17c-3.61,0-7.22-0.38-10.83-1.13c-3.61-0.75-7.07-1.82-10.37-3.22c-3.3-1.4-6.39-3.08-9.25-5.06    l6.23-12.16c0.61,0.61,1.7,1.41,3.27,2.4c1.57,0.99,3.47,1.98,5.72,2.96c2.25,0.99,4.7,1.82,7.36,2.5    c2.66,0.68,5.35,1.02,8.07,1.02c3.88,0,6.78-0.62,8.68-1.87c1.91-1.25,2.86-3.08,2.86-5.51c0-2.17-0.77-3.88-2.3-5.14    c-1.53-1.25-3.7-2.34-6.49-3.26s-6.1-1.88-9.91-2.9c-4.63-1.29-8.46-2.74-11.49-4.37c-3.03-1.63-5.31-3.64-6.85-6.05    c-1.53-2.41-2.3-5.45-2.3-9.11c0-4.95,1.19-9.11,3.58-12.46c2.38-3.36,5.58-5.9,9.6-7.63c4.02-1.73,8.48-2.6,13.38-2.6    c3.41,0,6.62,0.38,9.66,1.13c3.03,0.75,5.88,1.74,8.53,2.96c2.66,1.22,5.04,2.55,7.15,3.98L980.89,1318.1z"/>
		<path className="st1" d="M1061.61,1372.15c-5.72,0-10.61-0.97-14.66-2.91c-4.05-1.94-7.32-4.61-9.81-8.02    c-2.49-3.41-4.31-7.27-5.47-11.6c-1.16-4.33-1.74-8.84-1.74-13.54v-36.99h14v36.99c0,3,0.32,5.91,0.97,8.74    c0.65,2.82,1.64,5.36,2.96,7.61c1.33,2.25,3.12,4.02,5.36,5.31c2.25,1.29,5.04,1.94,8.38,1.94c3.48,0,6.32-0.66,8.53-1.99    c2.21-1.33,4-3.12,5.36-5.36c1.36-2.25,2.35-4.78,2.96-7.61c0.61-2.83,0.92-5.71,0.92-8.63v-36.99h14v36.99    c0,4.9-0.61,9.55-1.84,13.94c-1.23,4.39-3.1,8.24-5.62,11.55c-2.52,3.31-5.79,5.89-9.81,7.76    C1072.1,1371.21,1067.26,1372.15,1061.61,1372.15z"/>
		<path className="st1" d="M1138.24,1371.64v-72.55H1169c3.34,0,6.42,0.68,9.25,2.04c2.82,1.36,5.26,3.2,7.31,5.52    c2.04,2.32,3.63,4.91,4.75,7.77c1.13,2.86,1.69,5.79,1.69,8.79c0,4.02-0.92,7.89-2.76,11.6c-1.84,3.71-4.44,6.73-7.82,9.04    c-3.37,2.32-7.31,3.48-11.8,3.48h-17.27v24.32H1138.24z M1152.34,1334.96h16.35c1.77,0,3.34-0.47,4.7-1.43    c1.36-0.95,2.43-2.33,3.22-4.14c0.78-1.8,1.18-3.86,1.18-6.18c0-2.52-0.46-4.65-1.38-6.39c-0.92-1.74-2.11-3.06-3.57-3.98    c-1.47-0.92-3.05-1.38-4.75-1.38h-15.73V1334.96z"/>
		<path className="st1" d="M1232.55,1371.64v-72.55h30.75c3.34,0,6.42,0.68,9.25,2.04c2.82,1.36,5.26,3.2,7.31,5.52    c2.04,2.32,3.63,4.91,4.75,7.77c1.13,2.86,1.69,5.79,1.69,8.79c0,4.02-0.92,7.89-2.76,11.6c-1.84,3.71-4.44,6.73-7.82,9.04    c-3.37,2.32-7.31,3.48-11.8,3.48h-17.27v24.32H1232.55z M1246.65,1334.96H1263c1.77,0,3.34-0.47,4.7-1.43    c1.36-0.95,2.43-2.33,3.22-4.14c0.78-1.8,1.17-3.86,1.17-6.18c0-2.52-0.46-4.65-1.38-6.39c-0.92-1.74-2.11-3.06-3.57-3.98    c-1.47-0.92-3.05-1.38-4.75-1.38h-15.73V1334.96z"/>
		<path className="st1" d="M1326.85,1371.64v-72.55h14.1v60.18h36.99v12.36H1326.85z"/>
		<path className="st1" d="M1417.18,1371.64v-72.55h14.1v72.55H1417.18z"/>
		<path className="st1" d="M1527.42,1359.27v12.36h-50.37v-72.55h49.45v12.36h-35.35v17.47h30.45v11.44h-30.45v18.9H1527.42z"/>
		<path className="st1" d="M1569.11,1371.64v-72.55h32.19c3.27,0,6.32,0.68,9.14,2.04c2.83,1.36,5.28,3.2,7.36,5.52    c2.08,2.32,3.68,4.91,4.8,7.77s1.69,5.79,1.69,8.79c0,3.07-0.53,5.99-1.58,8.79c-1.06,2.79-2.57,5.26-4.55,7.41    c-1.98,2.15-4.29,3.8-6.95,4.96l16.55,27.28h-15.53l-14.92-24.32h-14.1v24.32H1569.11z M1583.21,1334.96h17.68    c1.77,0,3.34-0.53,4.7-1.58c1.36-1.06,2.45-2.47,3.27-4.24c0.82-1.77,1.23-3.74,1.23-5.92c0-2.25-0.48-4.26-1.43-6.03    c-0.96-1.77-2.15-3.17-3.58-4.19c-1.43-1.02-3-1.53-4.7-1.53h-17.17V1334.96z"/>
	</g>
	<g>
		<path className="st1" d="M1000.02,627.85l-313.9,543.66h48.3l265.6-460.02l265.6,460.02h48.26L1000.02,627.85z"/>
		<path className="st1" d="M1175.83,1099.62l-24.17-41.82l-24.13-41.82h-48.3l24.17,41.82l24.13,41.82l17.36,30.07H855.16l17.36-30.07    h-48.3l-41.49,71.89h434.59L1175.83,1099.62z M1000.02,795.13L896.64,974.16l-24.13,41.82l-24.13,41.82h48.26l24.17-41.82    l24.13-41.82l55.08-95.39l55.08,95.39h48.3L1000.02,795.13z"/>
		<polygon className="st1" points="1151.66,1057.8 848.38,1057.8 872.51,1015.98 1127.53,1015.98   "/>
	</g>
</g>
    </svg>

    </Link>

    <div className='hidden lg:flex  xl:gap-24  ms-24 justify-between gap-14 text-[13px]  xl:text-[17px]'>
<NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  }  to='/'>Home</NavLink>
  <NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/Adobe_Master_Collection_2024'>Adobe Master Collection 2024</NavLink>
<NavLink  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/Apps'>Apps</NavLink>
<NavLink className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/about-us'>About Us</NavLink>
    </div>
    </div>
    <div className=''>
        <button data-collapse-toggle="navbar-mobile" onClick={handleMobileNav} className={`block lg:hidden rounded-lg ${mobileNav ? 'ring ring-gray-200' : ''} `} aria-controls='navbar-mobile' aria-expanded={mobileNav} type='button'>
    <IoIosMenu aria-hidden="true"   size={30} />
        </button>
    <div id='navbar-mobile' onClick={handleMobileNav} className={`flex flex-col fixed h-screen z-[1] bg-[#00000099] gap-4 border-t-2 shadow-md w-screen top-[136px] left-0 p-1 lg:hidden ${mobileNav ? 'flex' : 'hidden'} `}>
      <div 
      
      onClick={handleMobileNav}
      className='bg-slate-100 w-3/4 [&>*]:border-b-2 h-full flex flex-col gap-4 [&>*]:py-3 [&>*]:px-4 [&>*]:text-[17px]'>
    <NavLink 
    onClick={handleMobileNav}
    className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  }  to='/'>Home</NavLink>
<NavLink  onClick={handleMobileNav} className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/Adobe_Master_Collection_2024'>Adobe Master Collection 2024</NavLink>
<NavLink onClick={handleMobileNav} className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/Apps'>Apps</NavLink>
<NavLink onClick={handleMobileNav} className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "text-blue-800" : "hover:text-blue-800"
  } to='/about-us'>About Us</NavLink>
 
      <NavLink onClick={handleMobileNav} className={({ isActive, isPending }) =>
    isPending ? "pending bg-[#FA0F00] px-2 py-1  hover:bg-slate-400 rounded-xl text-white" : isActive ? "bg-red-400 px-2 py-1 rounded-xl text-red" : " block bg-[#FA0F00] px-2 py-1 sm:px-2 sm:py-1 w-fit  rounded-xl self-center hover:bg-[#ff0001] text-white"
  } to='/contact-us'>Contact Us</NavLink>

  </div>
    </div>
    </div>
    <div onClick={() => dispatch(SHOW_CART())} className='me-3 relative cursor-pointer'>
  <PiShoppingCartDuotone size={30}  />
  <p className='text-white bg-red-600 absolute top-0 p-2 text-[12px] w-3 flex items-center justify-center h-3 right-0 rounded-full '>{cart.length}</p>

  </div>
    <NavLink className={({ isActive, isPending }) =>
    isPending ? "pending bg-[#FA0F00] px-6 py-3  hover:bg-slate-400 rounded-xl text-white" : isActive ? "bg-red-400 px-6 py-3 rounded-xl text-red" : "hidden lg:block bg-[#FA0F00] px-3 py-2 sm:px-6 sm:py-3  rounded-xl hover:bg-[#ff0001] text-white"
  } to='/contact-us'>Contact Us</NavLink>
</div>
    </div>
  )
}

export default Navbar