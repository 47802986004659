import React from 'react'
import { Link } from 'react-router-dom'
import AdobeSupp from '../assets/transparent background.gif'
const Hero = () => {
  return (
    <div >
    <div className='grid grid-cols-1 md:grid-cols-2  gap-5 items-center justify-center rounded-xl shadow-lg p-5'>
        <div  >
            <div className='flex flex-col items-center max-w-full'>
                <div className='max-w-full'>

                <h1 className='  text-[40px]  lg:text-[72px] leading-tight w-[489px] max-w-full font-medium font-poppins '>Unleash the Power of Visual Excellence.</h1>
                <p className='self-start text-[#374151] text-[17px] my-3'>Elevate Your Creativity with Adobe through Adobe Supplier</p>
                <Link to='/Apps' className='bg-blue-700 text-white py-[10px] px-5 rounded-lg my-4 hover:bg-blue-600 transition-all block w-fit'>
                    See our Programs
                </Link>
                </div>
            </div>
        </div>
        <div className='flex justify-center items-center'>
<img src={AdobeSupp} alt="Adobe Supplier" />
        </div>
    </div>
    </div>
  )
}

export default Hero