import { createSlice } from '@reduxjs/toolkit'

const initialState = {
apps: []
}

const AppsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    SET_APPS(state , action){
        state.apps = action.payload.apps;
    }
  }
});

export const {SET_APPS} = AppsSlice.actions
export const selectApps = (state) => state.apps.apps;

export default AppsSlice.reducer