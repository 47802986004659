import { createSlice } from '@reduxjs/toolkit'

const initialState = {
cart : localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [] ,
showCart: false
}

const cartProductsSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    SET_CART(state , action) {

        if(action.payload.updatedApps){
            state.cart = action.payload.updatedApps;
            localStorage.setItem('cart', JSON.stringify(action.payload.updatedApps));

        }else{
            const updateCart = [...state.cart , action.payload.product]
            state.cart = updateCart
            console.log(action.payload.product)
            localStorage.setItem('cart', JSON.stringify(updateCart));
        }

    },
    SHOW_CART(state){
state.showCart = true;
    },
    HIDE_CART(state){
state.showCart = false;
    },
    EMPTY_CART(state){
state.cart = [];
localStorage.removeItem('cart');

    }
  }
});

export const {SET_CART , SHOW_CART , HIDE_CART , EMPTY_CART} = cartProductsSlice.actions
export const selectCart = (state) => state.cart.cart 
export const selectshowCart = (state) => state.cart.showCart 
export default cartProductsSlice.reducer