import { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
// import { useNavigate , redirect } from 'react-router-dom'
import { storage } from "../../Firebase/config";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import ProductCard from "../../components/ProductCard";
// import { redirect, useNavigate } from 'react-router-dom'
// import useAuth from '../../hooks/useAuth'
import { Popconfirm } from "antd";
import {
  SET_SHOW_EDIT_PRODUCTS,
  showSelect,
} from "../../redux/editProducts/editProductsSlice";
import { useDispatch, useSelector } from "react-redux";
const Apps = () => {
  const show = useSelector(showSelect);
  const [add, setAdd] = useState(true);
  const [formData, setFormData] = useState({});
  const imageRef = useRef();
  const [discountError, setDiscountError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageTempUrl, setTemImageUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [imgProgress, setImgProgress] = useState(0);
  // const [open, setOpen] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const showPopconfirm = () => {
  //   setOpen(true);
  // };

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openStates, setOpenStates] = useState();

  const showPopconfirm = (productId) => {
    setOpenStates(productId);
  };

  const handleOk = (productId) => {
    setConfirmLoading(true);

    // Simulate asynchronous operation
    setTimeout(() => {
      setOpenStates(false);
      setConfirmLoading(false);
      handleDelete(productId);
    }, 2000);
  };
  const handleCancel = (productId) => {
    setOpenStates(false);
  };
  // const navigate = useNavigate()
  // const { auth } = useAuth();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(fetchedData);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
console.log(newItems)
    setFetchedData(newItems);


    try {
       await axios.post(
        `/api/products/updateOrder`,
        JSON.stringify({ newItems }),

        {
          headers: { "Content-Type": " application/json" },
        }
      );
      
      console.log(`updated successfully`);
  
    } catch (error) {
    
      console.error("Not updated");
    }

  };

  const handleImageUpload = () => {
    imageRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    console.log(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // reader.result contains the data URL
        const imageDataURL = reader.result;

        // Now you can use imageDataURL as a temporary URL
        setTemImageUrl(imageDataURL);
        // Perform other actions, e.g., send the data URL to the server
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);

      const storageRef = ref(storage, `images/${Date.now()}${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setImgProgress(parseInt(progress));
          // switch (snapshot.state) {
          //   case 'paused':
          //     break;
          //   case 'running':
          //     break;
          //     default:
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFormData({ ...formData, imageUrl: downloadURL });
          });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const total = formData.discountPrice
      ? formData.price - formData.discountPrice
      : formData.price;
    console.log(total);
    try {
      await axios.post(
        "/api/products",
        JSON.stringify({ ...formData, total }),

        {
          headers: { "Content-Type": " application/json" },
        }
      );
      // const data = response.data;
      const allinputs = document.querySelectorAll("input");
      allinputs.forEach((input) => {
        input.value = "";
      });
      const textarea = document.querySelectorAll("textarea");
      textarea.forEach((text) => {
        text.value = "";
      });
      setLoading(false);
      setFormData({});
      setErrorMess("");
      setSuccessMessage(`${formData.productname} added successfully`);
      setTemImageUrl("");
      setImageFile("");
      setImgProgress(0);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setErrorMess(error.response?.data?.message);
      setLoading(false);
      const desertRef = ref(storage, `images/${imageFile.name}`);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
      setFormData({});
    }
  };

  const hanfleFormChange = (e) => {
    const { id, value } = e.target;

    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    handleFetchProducts();
  }, [show]);

  const handleFetchProducts = async () => {
    setImageFile("");
    setTemImageUrl("");
    setFormData("");
    setImgProgress(0);
    setAdd(false);
    setSpinner(true);

    try {
      const response = await axios.get("/api/products");
      setFetchedData(response.data);
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    const discountprice = parseFloat(formData.discountPrice);
    const price = parseFloat(formData.price);
    const total = discountprice - price;
    if (price <= 0) {
      setPriceError("Product Price must be positive value");
      return;
    } else {
      setPriceError("");
    }
    if (discountprice < 0) {
      setDiscountError("Discount Price can not be negative value");
      return;
    }
    if (total >= 0) {
      setDiscountError("Discount Price must be less than the product price");
      return;
    }
    setPriceError("");
    setDiscountError("");
  }, [formData.discountPrice, formData.price]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/api/products/delete/${id}`);
      console.log(response.data);
      handleFetchProducts();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="flex p-2 gap-2 ring-1 rounded-xl w-full text-center">
        <p
          onClick={() => setAdd(true)}
          className={`${
            add ? "bg-slate-200" : "bg-slate-50 "
          } w-1/2 p-2 rounded-lg cursor-pointer text-[12px] sm:text-[16px]  hover:bg-slate-200 transition-colors duration-300 `}
        >
          Add Product
        </p>
        <p
          onClick={handleFetchProducts}
          className={`${
            add ? "bg-slate-50 " : "bg-slate-200"
          } w-1/2 p-2 rounded-lg cursor-pointer text-[12px] sm:text-[16px] hover:bg-slate-200 transition-colors duration-300  `}
        >
          View Products
        </p>
      </div>
      <div className="w-full ">
        {add ? (
          <div className="mt-12 w-full">
            <p className="text-red-600 text-[15px] font-semibold">
              {errorMess}
            </p>
            <p className="text-green-500 text-[20px] mb-5 font-semibold">
              {successMessage}
            </p>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
                <div className="sm:w-[266px] w-full max-w-full">
                  <div className="flex flex-col gap-3 mb-4">
                    <label htmlFor="productname">Product Name:</label>
                    <input
                      className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                      type="text"
                      id="productname"
                      placeholder="Adobe Acrobat ... "
                      required
                      onChange={(e) => hanfleFormChange(e)}
                    />
                  </div>
                  <div>
                    <div className="flex flex-col gap-3 mb-4 w-[200px] max-w-full h-[200px] rounded-2xl border-2 relative image group">
                      <input
                        className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                        ref={imageRef}
                        hidden
                        type="file"
                        id="image"
                        accept="image/*"
                        required
                        onChange={handleFileInputChange}
                      />
                      <img
                        src={imageTempUrl}
                        alt="product"
                        className={` ${
                          !imageTempUrl ? "hidden" : "block"
                        } max-w-full  rounded-2xl w-full h-full`}
                      />

                      <button
                        type="button"
                        onClick={handleImageUpload}
                        className={` ${
                          imageTempUrl ? "hidden" : "block"
                        } bg-slate-100 opacity-70 h-full w-full max-w-full absolute group-hover:block `}
                      >
                        Upload Image
                      </button>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                      <div
                        className={`bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full ${
                          imgProgress ? "block" : "hidden"
                        } `}
                        style={{ width: `${imgProgress}%` }}
                      >
                        {" "}
                        {imgProgress} %
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[266px] max-w-full">
                  <div className="flex flex-col gap-3 mb-4">
                    <label htmlFor="productname">Product Price:</label>
                    <input
                      onChange={(e) => hanfleFormChange(e)}
                      className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                      type="number"
                      id="price"
                      placeholder="€"
                      min={1}
                      required
                      readOnly={imgProgress > 0 && imgProgress < 100}
                    />
                    <p className="text-[12px] text-red-600">{priceError}</p>
                  </div>
                  <div className="flex flex-col gap-3 mb-4">
                    <label htmlFor="productname">Discount Price:</label>
                    <input
                      onChange={(e) => hanfleFormChange(e)}
                      className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                      type="number"
                      id="discountPrice"
                      placeholder="€"
                      min={1}
                      readOnly={imgProgress > 0 && imgProgress < 100}
                    />
                    <p className="text-[12px] text-red-600 max-w-full ">
                      {discountError}
                    </p>
                  </div>
                  <p htmlFor="stock">Stock Availability:</p>
                  <div className="flex flex-col gap-1 mt-1">
                    <div className="flex items-center gap-1">
                      <input
                        readOnly={imgProgress > 0 && imgProgress < 100}
                        onChange={(e) => hanfleFormChange(e)}
                        id="stock"
                        type="radio"
                        value={true}
                        checked
                        name="stock"
                        className="w-4 h-4  cursor-pointer text-blue-600 bg-gray-100 border-gray-300   dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-[13px]">In stock</label>
                    </div>
                    <div className="flex items-center gap-1">
                      <input
                        readOnly={imgProgress > 0 && imgProgress < 100}
                        onChange={(e) => hanfleFormChange(e)}
                        id="stock"
                        type="radio"
                        value={false}
                        name="stock"
                        className="w-4 h-4 cursor-pointer text-red-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-[13px]">Out of stock</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-3 mb-4">
                <label htmlFor="productname">Description:</label>
                <textarea
                  onChange={(e) => hanfleFormChange(e)}
                  className="py-1 px-2 border-2 rounded-lg focus:outline-none "
                  type="text"
                  id="description"
                  placeholder="Explain the product ... "
                  required
                  readOnly={imgProgress > 0 && imgProgress < 100}
                />
              </div>

              {/* <input className='bg-blue-700 text-white py-[10px] px-5 rounded-lg my-5 disabled:bg-blue-100 disabled:cursor-not-allowed cursor-pointer hover:bg-blue-600 transition-all block w-fit' type='submit' disabled={formData.discountError || formData.priceError} value='Add Product'  /> */}
              <button
                disabled={discountError || priceError || loading || !imageFile}
                type="submit"
                className={`  ${
                  discountError || priceError || !imageFile ? "opacity-40" : ""
                }   text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center`}
              >
                {loading && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}

                {loading ? "Loading..." : "Add Product"}
              </button>
            </form>
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd} className="flex justify-center  items-center bg-slate-100 my-3 w-full p-5">
            {spinner ? (
              <div role="status" className="flex items-center justify-center py-8">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Droppable droppableId="droppable" className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full gap-2">
      {(provided) => (
        <div {...provided.droppableProps} className="p-3 " ref={provided.innerRef}>
          {fetchedData.length ? (
            fetchedData.map((product, index) => (
              <Draggable key={product._id} draggableId={product._id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flex flex-col gap-2 max-w-full mb-3"
                  >
                    <ProductCard product={product} stock={product.stock} />
                    <div className="flex justify-evenly items-center ">
                        <button
                          type="button"
                          className="text-white py-1 px-2 text-[12px] bg-blue-700 rounded-lg hover:bg-blue-600"
                          onClick={() =>
                            dispatch(
                              SET_SHOW_EDIT_PRODUCTS({
                                id: product._id,
                              })
                            )
                          }
                        >
                          Edit
                        </button>
                        <Popconfirm
                          title={product.productname}
                          description="Delete this product?"
                          open={openStates === product._id}
                          onConfirm={() => handleOk(product._id)}
                          okButtonProps={{
                            loading: confirmLoading,
                            style: {
                              backgroundColor: "#1890ff",
                              color: "#fff",
                              border: "none",
                            }, // Add your custom styles here
                          }}
                          onCancel={handleCancel}
                        >
                          <button
                            type="button"
                            onClick={() => showPopconfirm(product._id)}
                            className="text-white py-1 px-2 text-[12px] bg-red-700 rounded-lg hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </Popconfirm>
                      </div>
                  </div>
                )}
              </Draggable>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center col-span-full">
              <h1 className="text-[18px] sm:text-[40px]">No products to view</h1>
              <p>Try Adding Some</p>
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
            )}
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default Apps;
