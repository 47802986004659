import React, { useEffect } from "react";
import { NavLink, Route, Routes, redirect, useNavigate } from "react-router-dom";
import EditApps from "./EditApps";
import Users from "./Home";
import { LuUsers } from "react-icons/lu";
import { TbAppsFilled } from "react-icons/tb";
import EditProducts from "../../components/EditProduct";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { showSelect } from "../../redux/editProducts/editProductsSlice";
import Home from "./Home";














const Admin = () => {

  const { auth } = useAuth();
  const navigate = useNavigate();
const showEditProduct = useSelector(showSelect)










  useEffect(() => {
    if(!auth?.accessToken){
      redirect("/login");

    }
  
  }, [auth , navigate])
  return (
    <>
    {showEditProduct ? <EditProducts/> : "" }
    <div className="flex  gap-7 px-[20px]   sm:px-[35px] bg-slate-50  py-6 ">
      <h1 className="hidden text-[20px] lg:block font-semibold font-poppins text-center basis-[3%]">Admin Page</h1>
      <div className=" basis-[5%] px-2  md:basis-[5%] flex flex-col items-center gap-6 bg-white rounded-2xl py-3 md:px-7">
        <NavLink to='/Admin/home'  className={({ isActive, isPending }) =>
    isPending ? "pending hover:text-blue-800" : isActive ? "flex items-center gap-4 bg-slate-50 p-1 md:px-3  md:py-2  rounded-2xl" : "flex items-center gap-4 hover:bg-slate-50 p-1 md:px-3 md:py-2  rounded-2xl"}>
        <LuUsers />
      <p className="hidden md:block" >Home</p>  
  </NavLink>
        <NavLink to='/Admin/apps'  className={({ isActive, isPending }) =>
        
    isPending ? "pending hover:text-blue-800" : isActive ? "flex items-center gap-4 bg-slate-50 p-1 md:px-3 md:py-2 rounded-2xl" : "flex items-center gap-4 hover:bg-slate-50 p-1 md:px-3 md:py-2  rounded-2xl"}>
      <TbAppsFilled />
      <p className="hidden md:block">Apps</p>  
  </NavLink>
        {/* <NavLink to='/Admin/apps'  className={({ isActive, isPending }) =>
        
    isPending ? "pending hover:text-blue-800" : isActive ? "flex items-center gap-4 bg-slate-50 p-1 md:px-3 md:py-2 rounded-2xl" : "flex items-center gap-4 hover:bg-slate-50 p-1 md:px-3 md:py-2  rounded-2xl"}>
      <TbAppsFilled />
      <p className="hidden md:block">All Products</p>  
  </NavLink> */}
  
      </div>
      <div className=" basis-[92%] rounded-2xl items-center md:basis-[92%] bg-white py-4 sm:px-7 px-[6px] ">
        <Routes>

      <Route path="apps" element={<EditApps/>} />
        <Route path="home" element={<Home/>} />
        {/* <Route path="all-products" element={<AllProducts />} /> */}
        </Routes>
      </div>
    </div>
    </>
  );
};

export default Admin;
