import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import axios from "../api/axios";
import {Skeleton } from "antd";
import { useDispatch } from "react-redux";
import { SET_APPS } from "../redux/apps/AppsSlice";

const Apps = () => {
  const dispatch = useDispatch()
  const [fetchedData, setFetchedData] = useState([]);
  const [dataCopy, setDataCopy] = useState([])
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  useEffect(() => {
    handleFetchProducts();
  }, []);
  
  const handleFetchProducts = async () => {
    try {
      const response = await axios.get("/api/products");
      setFetchedData(response.data);
      setDataCopy(response.data);
      dispatch(
        SET_APPS({
          apps: response.data
        })
      )
      setLoading(false)
      // setSpinner(false);
    } catch (error) {
      // setSpinner(false);
    }
  };


useEffect((e) => {
  if(search){

  
const data = dataCopy
const filter = data?.filter(data => data.productname.toLowerCase().includes(search.toLowerCase()));

if(filter.length) {
setFetchedData(filter)
}else{
  handleFetchProducts()
}
  }else{
    handleFetchProducts()
  }




},[search])











  return (
    <div className="flex flex-col gap-9 p-6 w-full">
      <h1 className="text-[40px]">
         Pricing for Creative Cloud apps and more.
      </h1>
       
    <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input value={search} onChange={(e) => setSearch(e.target.value)} type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="After Effects, InDesign..." required/>
        {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
    </div>

      <div className="grid grid-cols-1  md:grid-cols-2  rounded-lg xl:grid-cols-4 w-full gap-3 bg-slate-200 p-3">
        {fetchedData.length ? (
          fetchedData.map((product, i) => (
            <Skeleton key={i} loading={loading} className="flex flex-col gap-2 max-w-full">
              <ProductCard product={product} addtocart={true} stock={product.stock}/>
            </Skeleton>
          ))
        ) : (
         
              <Skeleton avatar title={false} loading={true} active/>
               
          )}
          
      
        
      </div>
    </div>
  );
};

export default Apps;
