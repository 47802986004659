import React from "react";
import aboutus from "../assets/aboutus.png";
const AboutUs = () => {
  return (
    <div className="mt-[110px]">
      <div className=" flex flex-col items-center px-6">
        <h1 className="text-[poppins] mb-[30px] text-[48px] font-semibold">
          About Us
        </h1>
        <p>
          Welcome to our website, your premier destination for all things Adobe
          apps and services. As an independent provider, we are dedicated to
          sourcing the best deals for our valued customers. 
        </p>
        <div className="mt-[87px] mb-[39px]">
          <img src={aboutus} alt="about-us" />
        </div>
        <h1 className="text-[poppins] mb-[30px] text-[48px] font-semibold">
          Our Mission
        </h1>
        <p>Our mission is
          simple: to ensure you have access to the latest and most innovative
          Adobe applications on the market. At our core, we are committed to
          keeping our catalog updated with the newest Adobe apps as soon as they
          hit the market. Whether it's the latest version of Photoshop,
          Illustrator, Premiere Pro, or any other Adobe software, you can trust
          us to have it readily available for you. With our passion for
          technology and commitment to customer satisfaction, we strive to make
          your Adobe experience seamless and affordable. Whether you're a
          professional designer, photographer, videographer, or simply someone
          who loves to explore the creative possibilities, we have the tools you
          need to bring your vision to life.</p>
      </div>
    </div>
  );
};

export default AboutUs;
