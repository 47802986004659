import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Apps from "./pages/Apps";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import MasterCollection from "./pages/MasterCollection";
import Admin from "./pages/admin/Admin";
import Unauthorized from "./components/Unauthorized";
import PersistLogin from "./components/PersistLogin";
import Register from "./pages/Register";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import Missing from "./components/Missing";
import Cart from "./components/Cart";
import { useDispatch, useSelector } from "react-redux";
import { selectshowCart } from "./redux/cartProducts/cartProductsSlice";
import { useEffect } from "react";
import axios from "./api/axios";
import { SET_APPS } from "./redux/apps/AppsSlice";
import { CheckoutForm } from "./pages/CheckoutForm";
import StripeReturn from "./pages/StripeReturn";
import ScrollToTop from "./components/ScrollToTop";
const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
};

function App() {
  const dispatch = useDispatch();
const location = useLocation();
const { pathname } = location;

  useEffect(() => {
    handleFetchProducts();
  }, []);

  const handleFetchProducts = async () => {
    try {
      const response = await axios.get("/api/products");
      dispatch(
        SET_APPS({
          apps: response.data,
        })
      );
      // setSpinner(false);
    } catch (error) {
      // setSpinner(false);
    }
  };

  const showCart = useSelector(selectshowCart);
  return (
    <div className="App">
            <ScrollToTop />
     {pathname.includes('checkout') || pathname.includes('return') ? 
      ""
      :
      <Navbar />
    }
      {showCart ? <Cart /> : ""}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/Adobe_Master_Collection_2024"
          element={<MasterCollection />}
        />
        <Route path="/Apps" element={<Apps />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<StripeReturn />} />

        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="/" element={<HomePage />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="Admin/*" element={<Admin />} />
          </Route>
        </Route>

        <Route path="*" element={<Missing />} />
      </Routes>
      {pathname.includes('checkout') || pathname.includes('return') ? 
      ""
      :
      <Footer />
    }
    </div>
  );
}

export default App;
