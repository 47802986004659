import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from "../api/axios";
import { useSelector } from "react-redux";
import { selectCart } from "../redux/cartProducts/cartProductsSlice";
import { useNavigate } from 'react-router-dom';
import { Skeleton } from "antd";

const stripePromise = loadStripe(`pk_live_51OQFHrHYYvjEyZBnzraJVPDVtve9XgwegUNGv8g0FvsN0SfH1XWdsRUVLUS4ySl75ZfgYcV9LYyBEdXXsXnwf3Ai00InOtrg5m`);

export const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [loading, setLoading] = useState(true);

  const cart = useSelector(selectCart);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        if (cart.length && !clientSecret) {
          const response =   await axios.post(
            "/stripe/create-checkout-session",
            {cart},
    
            {
              headers: { "Content-Type": " application/json" },
              withCredentials: true,
            }
          );
          setClientSecret(response.data.clientSecret);
          setSessionId(response.data.sessionId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, [cart, clientSecret]); // Run this effect only when cart or clientSecret changes

  const expireCheckoutSession = async () => {
    try {
      if (sessionId) {
        await axios.post(
            "/stripe/expire-checkout-session",
            {sessionId},
    
            {
              headers: { "Content-Type": " application/json" },
              withCredentials: true,
            }
          );
      }
      navigate('/', { replace: true });
      console.log("Checkout session expired successfully");
    } catch (error) {
      console.error("Error expiring checkout session:", error);
    }
  };

  return (
    !!cart.length && (
      <div className="px-3">
        <button
          className="mb-4 p-3 bg-[#f3e7e7] text-black rounded-xl font-medium"
          type="button"
          onClick={expireCheckoutSession}
          disabled={loading}
        >
          {loading ? (
            <Skeleton.Button className="bg-transparent" active={true} />
          ) : (
            "Cancel"
          )}
        </button>
        <div id="checkout">
          {clientSecret !== null && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      </div>
    )
  );
};
