import React from "react";
import { useRef, useState, useEffect } from "react";
import axios from "../api/axios";
// import AuthContext from "./context/AuthProvider";
import useAuth from '../hooks/useAuth';
import {Link  , useLocation , useNavigate} from "react-router-dom"
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";

const LOGIN_URL = "/api/auth";

const Login = () => {
  const { setAuth ,auth  } = useAuth();

const navigate = useNavigate()
const location = useLocation()
const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, resetUser, userAttributeObj] = useInput('user' , '') //useLocalstorage('user' , '') //useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
const [check, toggleCheck] = useToggle('persist' , false)
  useEffect(() => {
    userRef.current.focus();
  }, [auth]);

  

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, password: pwd }),

        {
          headers: { "Content-Type": " application/json" },
          withCredentials: true,
        }
      );

const accessToken = response?.data?.accessToken;
const roles = response?.data?.roles;
setAuth({user , accessToken , roles});
      setPwd("");
      // setUser("");
      resetUser();
      navigate(from , { replace: true})
    } catch (err) {
        if(err?.response){
            setErrMsg("No server response")
        }else if(err.response?.status === 400){
setErrMsg("Missing Username or Password")
        }else if(err.response?.status === 401){
            setErrMsg("Unauthorized")

        }else{
            setErrMsg("Login Failed")
            console.log(err.message);
        }
errRef.current.focus();


    }
  };

  // const togglePersist = () => {
  //   setPersist(prev => !prev)

  // }

  // useEffect(() => {
    

  //     localStorage.setItem('persist' , persist)

  // }, [persist])
  

  return (
    <div className="flex items-center justify-center p-6">
     
        <section className="flex flex-col shadow-xl rounded-xl border-red-600 border-2 w-[350px] max-w-full items-center gap-4 py-3 px-4">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          
          <h1 className="font-poppins font-semibold text-[48px] mb-4">Sign In</h1>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-3 mb-2">

            <label htmlFor="username">Username:</label>
            <input
              type="text"
              ref={userRef}
              id="username"
              autoComplete="off"
              {...userAttributeObj}
              required
              className="border p-2 focus:outline-none  rounded-lg"
              placeholder="Username"
              />
              </div>
              <div className="flex flex-col gap-3 mb-2">

            <label htmlFor="password">Password:</label>
            <input
              type="password"
              ref={userRef}
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
              className="border p-2 focus:outline-none  rounded-lg"
placeholder="Password"
            />
              </div>

            <button className='bg-blue-700 text-white py-[10px] px-5 rounded-lg my-4 hover:bg-blue-600 transition-all block w-fit' type="submit">Sign In</button>
            <div className="persistCheck">
              <input type="checkbox"
              id="persist"
              onChange={toggleCheck}
              checked={check}

              
              />
              <label htmlFor="persit">Trust This Device</label>
            </div>
          </form>
          <div className="self-start">

          <p>
            Need An Account?
            <br />
            <span className="line">
              <Link to="/register" className="text-blue-700">Sign Up</Link>
            </span>
          </p>
          </div>
        </section>
      
    </div>  );
};

export default Login;
