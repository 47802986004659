import { useEffect, useState } from "react";
import Ques from "../assets/ques.png";
import { FaCheckCircle } from "react-icons/fa";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import axios from "../api/axios";
import { Link } from "react-router-dom";
import ad from "../assets/1704530395504acrobat.svg";
import ae from "../assets/1704530442053after-effects.svg";
import an from "../assets/1704530476153animate.svg";
import au from "../assets/1704530506178audition.svg";
import br from "../assets/1704530531170640px-Adobe_Bridge_CC_icon.svg.png";
import ch from "../assets/1704530556631640px-Adobe_Character_Animator_icon_(2020).svg.png";
import me from "../assets/1704531058310Adobe_Media_Encoder_Icon.svg.png";
import ai from "../assets/1704530476153animate.svg";
import ic from "../assets/1704530738082incopy.svg";
import id from "../assets/1704530952393indesign-5981328_960_720.webp";
import lr from "../assets/Image (3).png";
import ps from "../assets/1704533513118photoshop.svg";
import pr from "../assets/1704533570253premiere.svg";
import { loadStripe } from '@stripe/stripe-js';

const images = [ad, ae, an, au, br, ch, me, ai, ic, id, lr, ps, pr];
const Programs = [
  "Adobe Acrobat Pro DC 2023",
  "Adobe After Effects 2024",
  "Adobe Animate 2024",
  "Adobe Audition 2024",
  "Adobe Bridge 2024",
  "Adobe Character Animator",
  "Adobe Illustrator 2024",
  "Adobe Incopy 2024",
  "Adobe Indesign 2024",
  "Adobe Lightroom Classic",
  "Adobe Media Encoder 2024",
  "Adobe Photoshop 2024",
  "Adobe Premiere Pro 2024",
];






const stripePromise = loadStripe(`pk_live_51OQFHrHYYvjEyZBnzraJVPDVtve9XgwegUNGv8g0FvsN0SfH1XWdsRUVLUS4ySl75ZfgYcV9LYyBEdXXsXnwf3Ai00InOtrg5m`); // Replace with your actual publishable key







const handleAcc = (e) => {
  const accordion = document.querySelectorAll(".accordion");
  accordion.forEach((element) => {
    if (element === e.target.closest(".accordion")) {
      const answer = element.querySelector(".answer");
      const up = element.querySelector(".up");
      const down = element.querySelector(".down");

      if (answer.classList.contains("opacity-100")) {
        answer.classList.remove("block");
        answer.classList.remove("opacity-100");
        answer.classList.add("hidden");
        answer.classList.add("opacity-0");
        up.classList.remove("block");
        up.classList.add("hidden");
        down.classList.remove("hidden");
        down.classList.add("block");
      } else {
        answer.classList.remove("hidden");
        answer.classList.remove("opacity-0");
        answer.classList.add("block");
        answer.classList.add("opacity-100");
        down.classList.remove("block");
        down.classList.add("hidden");
        up.classList.remove("hidden");
        up.classList.add("block");
      }
    }
  });
};

const MasterCollection = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [formData, setFormData] = useState('')
  useEffect(() => {
    const   handleFetch = async() => {
      try {
        const res = await axios.get(
          `/api/master_collection_prices`
        );
        console.log(res.data[0]?.prevPrice);
        if(res.data.length > 0) {
         setFormData({prevPrice : res.data[0]?.prevPrice.$numberDecimal , currentPrice: res.data[0]?.currentPrice.$numberDecimal })
        }
      } catch (error) {
        console.log(error);
      }
   
       }
       handleFetch()
     
     }, [])
  


  useEffect(() => {
    const handleFetchProducts = async () => {
      try {
        const response = await axios.get("/api/products");

        setFetchedData(response.data);
        // setSpinner(false);
      } catch (error) {
        // setSpinner(false);
      }
    };
    handleFetchProducts();
  }, []);






  const handleClick = async () => {
    try {
      const response= await axios.post(
        "/stripe/create-payment-link",
        JSON.stringify({
          productName: "Adobe Master Collection 2024",
          description: "Unlock the full spectrum of creative possibilities with the Adobe Master Collection 2024, a powerhouse bundle of industry-leading applications tailored for professionals and enthusiasts alike. This collection seamlessly integrates cutting-edge technology with intuitive design, empowering you to bring your artistic visions to life. Elevate your creative journey with this comprehensive suite that includes:",
          price: formData.currentPrice,
        }),

        {
          headers: { "Content-Type": " application/json" },
          withCredentials: true,
        }
      );
      
     
      
      const data = response.data;
      window.location.href = data;

    } catch (error) {
      console.error("Error creating payment link:", error);
    }
  };
  















  return (
    <div className="px-[10px] max-w-full   sm:px-[35px] mt-[111px]">
      <div className="flex flex-col items-center gap-4 px-3">
        <div className="text-center mb-[49px]  items-center flex w-full flex-col">
          <h1 className=" text-[27px] sm:text-[35px] md:text-[50px] font-poppins mb-[50px] font-semibold animate-jump-in animate-once animate-duration-2000 animate-delay-700">
            Introducing <br /> Adobe Master Collection 2024{" "}
          </h1>
          <div
            className={` items-center gap-3 mb-[80px] hidden lg:flex flex-wrap w-[588px]`}
          >
            {images
              ? images.map((product, i) => (
                  <img
                    key={i}
                    className={`w-[60px] h-[60px] `}
                    src={product}
                    alt="adobe"
                  />
                ))
              : ""}
          </div>
          <p className="w-3/4 break-words text-lg mb-[50px]">
            Unlock the full spectrum of creative possibilities with the Adobe
            Master Collection 2024, a powerhouse bundle of industry-leading
            applications tailored for professionals and enthusiasts alike. This
            collection seamlessly integrates cutting-edge technology with
            intuitive design, empowering you to bring your artistic visions to
            life. Elevate your creative journey with this comprehensive suite
            that includes:
          </p>
          <h1 className="font-poppins font-semibold text-[48px]">Pricing</h1>
          <p className="text-[20px] text[#030303] font-light">
            Our pricing is not expensive, but it's not <br /> cheap either, it's
            exactly what it should be
          </p>
        </div>
        <div className="shadow-2xl px-[37px] py-[67px] w-[403px] max-w-full rounded-xl">
          <div>
            <h4 className="text-[20px] font-semibold font-poppins">pricing</h4>
            <div className="mt-2">
             {formData?.prevPrice && <p
                className={`text-[30px] w-fit font-poppins  line-through text-slate-950 bg-lime-300`}
              >
                {formData?.prevPrice}€
              </p>
}
              <p className="text-[50px] font-poppins font-semibold">{formData?.currentPrice}€</p>
            </div>
            <p className="mb-[50px]">
              Your Complete Creative <br /> Suite
            </p>
          </div>
          <button
          onClick={handleClick}
            className="bg-blue-700 text-center text-white py-[10px] px-5 rounded-lg my-4 hover:bg-blue-600 transition-all block w-full"
          >
            Continue
          </button>
          <p className="text-[12px] font-medium text-blue-700 ">
            *Only for Windows Users
          </p>
          <p className="text-[12px] ">
            Please note: Adobe Fresco, Adobe XD and Adobe Rush are visible in
            the setup list of Adobe Master Collection 2024 but are not included.
          </p>
        </div>
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-col items-center w-full max-w-full box-border px-[20px] md:px-[161px] mt-[111px]">
            <h2 className="font-poppins text-[32px]   mb-[81px]    ">
              Features
            </h2>
            <p className="w-full  h-1 text-black border-t"></p>
          </div>
          <div className="  shadow-xl py-3 px-2 sm:px-5 w-[95%] mt-2">
            <div className="flex flex-col  gap-4 mt-[34px]">
              {Programs.map((prog) => (
                <div key={prog} className="flex justify-center w-full items-center  ">
                  <div className="">
                    <FaCheckCircle className="  w-[15px] h-[15px]   sm:w-[25px] sm:h-[25px]" color="#3164F4" />
                  </div>
                  <p className="text-[#030303] text-[12px]  font-bold sm:font-normal sm:text-[17px] w-72 max-w-full text-center">
                    {prog}
                  </p>
                </div>
              ))}
            </div>

            {/* <div className="flex flex-col gap-4 mt-[34px]">
              {Programs.slice(8, 16).map((prog) => (
                <div key={prog} className="flex items-center gap-2 ">
                  <div className="basis-2/12">
                    <FaCheckCircle size={25} color="#3164F4" />
                  </div>
                  <p className="text-[#030303] text-[17px] basis-10/12">
                    {prog}
                  </p>
                </div>
              ))}
            </div> */}
            {/* <div className="flex flex-col gap-4 mt-[34px]">
              {Programs.slice(16, 23).map((prog) => (
                <div key={prog} className="flex items-center gap-2">
                  <div className="basis-2/12">
                    <FaCheckCircle size={25} color="#3164F4" />
                  </div>
                  <p className="text-[#030303] text-[17px] basis-10/12">
                    {prog}
                  </p>
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="mt-20 max-w-full p-3">
          <div className="text-center flex flex-col items-center">
            <img
              className="w-52 sm:w-72 max-w-full"
              src={Ques}
              alt="questions"
            />
            <h1 className="sm:text-[48px] text-[40px] font-semibold">
              Frequently asked <br /> questions
            </h1>
            <div className="flex flex-col max-w-full gap-4 mt-[45px]">
              <div className="w-[450px] max-w-full border-b-2 pb-[20px]">
                <div
                  onClick={(e) => handleAcc(e)}
                  className="accordion cursor-pointer flex flex-col items-center justify-between "
                >
                  <div className="flex w-full items-center justify-between ">
                    <p className="ques">
                      Q: On which operating system can I use this?{" "}
                    </p>
                    <FaAngleUp className="up hidden cursor-pointer" />
                    <FaAngleDown className="down block cursor-pointer" />
                  </div>
                  <p className=" answer hidden opacity-0 transition-opacity  duration-1000 ease-in-out my-[10px] ">
                    A: Only Windows
                  </p>
                </div>
              </div>
              <div className="w-[450px] max-w-full border-b-2 pb-[20px]">
                <div
                  onClick={(e) => handleAcc(e)}
                  className="accordion cursor-pointer  flex flex-col items-center justify-between "
                >
                  <div className="flex w-full items-center justify-between ">
                    <p className="ques">
                      Q: Will my apps update when there is a new Adobe release?
                    </p>
                    <FaAngleUp className="up hidden cursor-pointer" />
                    <FaAngleDown className="down block cursor-pointer" />
                  </div>
                  <p className="hidden answer my-[10px] ">
                    A: No, this will not update to newer versions that are
                    coming, the applications however will always keep working.
                  </p>
                </div>
              </div>
              <div className="w-[450px] max-w-full border-b-2 pb-[20px]">
                <div
                  onClick={(e) => handleAcc(e)}
                  className="accordion cursor-pointer flex flex-col items-center justify-between "
                >
                  <div className="flex w-full items-center justify-between ">
                    <p className="ques">
                      Q: Will this work with Adobe Creative Cloud?
                    </p>
                    <FaAngleUp className="up hidden cursor-pointer" />
                    <FaAngleDown className="down block cursor-pointer" />
                  </div>
                  <p className="hidden answer my-[10px] ">
                    A: No, this will not work with creative cloud. This is a
                    standalone pack with no additional costs per month.
                  </p>
                </div>
              </div>
              <div className="w-[450px] max-w-full border-b-2 pb-[20px]">
                <div
                  onClick={(e) => handleAcc(e)}
                  className="accordion cursor-pointer flex flex-col items-center justify-between "
                >
                  <div className="flex  w-full items-center justify-between ">
                    <p className="ques">Q: Will this work with AI modules?</p>
                    <FaAngleUp className="up hidden cursor-pointer" />
                    <FaAngleDown className="down block cursor-pointer" />
                  </div>
                  <p className="hidden answer my-[10px] ">
                    A: No internet connection, so also no AI modules.
                  </p>
                </div>
              </div>
              <div className="w-[450px] max-w-full border-b-2 pb-[20px]">
                <div
                  onClick={(e) => handleAcc(e)}
                  className="accordion cursor-pointer flex flex-col items-center justify-between "
                >
                  <div className="flex w-full items-center justify-between ">
                    <p className="ques">Q: Can I get support from Adobe?</p>
                    <FaAngleUp className="up hidden cursor-pointer" />
                    <FaAngleDown className="down block cursor-pointer" />
                  </div>
                  <p className="hidden answer my-[10px] ">
                    A: You can only get support from adobesupplier.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterCollection;
