import img from '../assets/Image (5).png'
import { Link } from 'react-router-dom'

const Solutions = () => {
  return (
    <div className='flex justify-center mt-[120px] '>
      <div className='grid grid-cols-1 md:grid-cols-2 w-[1150px]  gap-5 items-center justify-center rounded-2xl shadow-2xl '>
        <div  >
            <div className='flex flex-col items-center pt-[93px] pb-[71px] px-[69px] max-w-full'>
                <div className='max-w-full'>

                <h1 className='  text-[20px]  lg:text-[48px] leading-tight w-[489px] max-w-full font-medium font-poppins '>Introducing Adobe Master Collection 2024
</h1>
                <p className='self-start text-[#374151] text-[17px] my-[20px]'>Make anything you can imagine, from <br/> gorgeous images, graphics, and art to <br/> standout social posts, videos, PDFs,<br/> and more.</p>
                <Link to='/Adobe_Master_Collection_2024' className='bg-blue-700 text-white py-[10px] px-5 rounded-lg  hover:bg-blue-600 transition-all block w-fit'>
                    Explore our offer
                </Link>
                </div>
            </div>
        </div>
        <div className='flex justify-center items-center'>
<img src={img} alt="Adobe Supplier" />
        </div>
    </div>
    </div>
  )
}

export default Solutions