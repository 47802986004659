// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBza6mACDZkZDDkMAoBzskA72f5_IwvWDU",
  authDomain: "adobesupplier-19e54.firebaseapp.com",
  projectId: "adobesupplier-19e54",
  storageBucket: "adobesupplier-19e54.appspot.com",
  messagingSenderId: "1056870771365",
  appId: "1:1056870771365:web:1f0347c6646029f0bd7b4c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
