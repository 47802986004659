import img from '../assets/Image.png'
import img1 from '../assets/Image (1).png'
import img2 from '../assets/Image (2).png'
import img3 from '../assets/Image (3).png'
import img4 from '../assets/Image (4).png'

const programs = [img,img1, img2, img3, img4]

const Programs = () => {
  return (
    <div className='mt-24 flex flex-col items-center text-center'>
        <div className='flex flex-col items-center'>
       <p className='text-[#303a4a]'>
        Unlock boundless creative possibilities with <br/> Adobe's exceptional suite of programs
        
        </p> 
        <div className="grid grid-cols-2 items-center justify-center md:grid-cols-3 lg:grid-cols-5  gap-4 mt-[30px] ">
{programs.map((program , i) => (
    <img key={i} src={program} className='w-[77px] h-[77px] ' alt="program" />
    ))}
        </div>
    </div>
    </div>
  )
}

export default Programs